import {
    Box,
    Center,
    Heading,
    Stack,
    Text,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function WhoWeAre() {
    return (
        <Box as="section" bg={mode('gray.50', 'gray.800')} py="20">
            <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                <Stack spacing={{ base: '4', lg: '20' }} direction={{ base: 'column', lg: 'row' }} alignContent='center'>
                    <Box maxW={{ lg: 'lg' }} alignContent='start'>
                        <Heading
                            size="2xl"
                            mt={{ base: '10', md: '20' }}
                            fontWeight="extrabold"
                            letterSpacing="tight"
                            lineHeight="normal"
                            color={mode('gray.800', 'gray.50')}
                            fontSize='5xl'
                        >
                            Who we are
                        </Heading>
                        <Text fontSize="lg" mt="6" color={mode('gray.600', 'gray.400')}>
                            Walawong is focused on enabling fintech companies with the right tools to create crypto or
                            blockchain products demanded by their end-users. 
                            Our developer friendly platform handles
                            the heavy lifting of integrating with the vast number of exisiting crypto related services
                            so that our clients can focus on building user-centric products.
                        </Text>
                    </Box>
                    <Center flex="1" minH="26rem" maxW={{ lg: 'xl' }}>
                        <StaticImage
                            src="../../assets/images/OurStoryIntro.png"
                            alt="OurStory"
                        />
                    </Center>
                </Stack>
            </Box>
        </Box>
    )
}