import React from 'react'
import Layout from 'components/Layout'
import Seo from 'components/Seo'
import WhoWeAre from 'pages/ourStory/WhoWeAre'
// import Partner from 'pages/ourStory/Partner'
import Security from 'pages/ourStory/Security'
// import Customer from 'pages/ourStory/Customer'
import Ready from 'pages/home/Ready'

function OurStoryPage() {
    return (
        <Layout isFullWidth>
            <Seo title="Our Story" />
            <WhoWeAre />
            {/* <Partner /> */}
            <Security/>
            {/* <Customer/> */}
            <Ready/>
        </Layout>
    )
}

export default OurStoryPage
